import React, { useEffect } from "react";
import Header from "../../components/Header";
import EventList from "../../components/Event/EventList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import add from "../../img/add.svg";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";

import { getEvent } from "../../selectors/event";
import { listEvent, deleteEvent } from "../../actions/event";

const EventListPage = ({
  listEvent,
  updateEvent,
  deleteEvent,
  eventList,
}) => {
  const { isFetching, isFetched, listData } = eventList;
  const {
    listData: eventListData,
    isFetching: eventListFetching,
    isFetched: eventListFetched,
  } = eventList;

  useEffect(() => {
    listEvent({ '_sort': 'updated_at:DESC' });
  }, []);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <h2 className="text-center text-2xl uppercase leading-tight mt-10 mb-10">
          Настани
        </h2>
        <div className="text-right">
          <div className="flex justify-end">
            <Link
              to="/createEvent"
              className="flex flex-row align-items align-middle items-center justify-end"
            >
              <div className="mr-4 text-blue">Додади настан</div>
              <img
                src={add}
                className="rounded-full bg-blue w-10 h-10 p-3"
                alt="Add button"
              />
            </Link>
          </div>
        </div>
        <EventList event={eventListData} deleteEvent={deleteEvent} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  eventList: getEvent(state),
});

const mapDispatchToProps = {
  listEvent,
  deleteEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventListPage);
