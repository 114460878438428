import _remove from 'lodash/remove';
import * as EventmessageActions from '../actions/eventmessage';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  eventmessageList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  eventmessageDetails: {
    isFetching: false,
    isFetched: false,
    eventmessageData: null,
  },
};

const setEventmessageListFetching = fetching => state => ({
  ...state,
  eventmessageList: {
    ...state.eventmessageList,
    isFetching: fetching,
  },
});

const setEventmessageList = (state, { payload: { listData, total } }) => ({
  ...state,
  eventmessageList: {
    ...state.eventmessageList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setEventmessageFetching = fetching => state => ({
  ...state,
  eventmessageDetails: {
    ...state.eventmessageDetails,
    isFetching: fetching,
  },
});

const setEventmessage = (state, { payload: { eventmessageData } }) => ({
  ...state,
  eventmessageDetails: {
    ...state.eventmessageDetails,
    eventmessageData,
    isFetching: false,
    isFetched: true,
  },
});

const setEventmessageDeleted = (state, { payload: { eventmessageId } }) => {
  const newListData = state.eventmessageList.listData;
  _remove(newListData, { id: eventmessageId });

  const newState = {
    ...state,
    eventmessageList: {
      ...state.eventmessageList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [EventmessageActions.LIST_EVENTMESSAGE_REQUEST]: setEventmessageListFetching(true),
  [EventmessageActions.LIST_EVENTMESSAGE_SUCCESS]: setEventmessageList,
  [EventmessageActions.LIST_EVENTMESSAGE_FAILURE]: setEventmessageListFetching(false),
  [EventmessageActions.DELETE_EVENTMESSAGE_SUCCESS]: setEventmessageDeleted,
  [EventmessageActions.FETCH_EVENTMESSAGE_REQUEST]: setEventmessageFetching(true),
  [EventmessageActions.FETCH_EVENTMESSAGE_SUCCESS]: setEventmessage,
  [EventmessageActions.FETCH_EVENTMESSAGE_FAILURE]: setEventmessageFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


