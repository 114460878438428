import _remove from 'lodash/remove';
import * as AdviceActions from '../actions/advice';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  adviceList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  adviceDetails: {
    isFetching: false,
    isFetched: false,
    adviceData: [],
  },
};

const setAdviceListFetching = fetching => state => ({
  ...state,
  adviceList: {
    ...state.adviceList,
    isFetching: fetching,
  },
});

const setAdviceList = (state, { payload: { listData, total } }) => ({
  ...state,
  adviceList: {
    ...state.adviceList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setAdviceFetching = fetching => state => ({
  ...state,
  adviceDetails: {
    ...state.adviceDetails,
    isFetching: fetching,
  },
});

const setAdvice = (state, { payload: { adviceData } }) => ({
  ...state,
  adviceDetails: {
    ...state.adviceDetails,
    adviceData,
    isFetching: false,
    isFetched: true,
  },
});

const setAdviceDeleted = (state, { payload: { adviceId } }) => {
  const newListData = state.adviceList.listData;
  _remove(newListData, { id: adviceId });

  const newState = {
    ...state,
    adviceList: {
      ...state.adviceList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [AdviceActions.LIST_ADVICE_REQUEST]: setAdviceListFetching(true),
  [AdviceActions.LIST_ADVICE_SUCCESS]: setAdviceList,
  [AdviceActions.LIST_ADVICE_FAILURE]: setAdviceListFetching(false),
  [AdviceActions.DELETE_ADVICE_SUCCESS]: setAdviceDeleted,
  [AdviceActions.FETCH_ADVICE_REQUEST]: setAdviceFetching(true),
  [AdviceActions.FETCH_ADVICE_SUCCESS]: setAdvice,
  [AdviceActions.FETCH_ADVICE_FAILURE]: setAdviceFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


