import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { createEvent, updateEvent, fetchEvent } from "../../actions/event";
import { getEventDetails } from "../../selectors/event";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";
import moment from "moment";
import EventMessageList from "../../components/Event/EventMessageList";
import {
  getEventmessageDetails,
  getEventmessages,
} from "../../selectors/eventmessage.js";
import {
  listEventmessages,
  createEventmessage,
  updateEventmessage,
  deleteEventmessage,
} from "../../actions/eventmessage.js";


const EventSchema = yup.object().shape({
  title: yup.string().required("Ова поле е задолжително"),
  description: yup.string().required("Ова поле е задолжително"),
  link: yup.string().url(),
  startDate: yup.date().required("Ова поле е задолжително"),
  endDate: yup.date().required("Ова поле е задолжително"),
  featuredImage: yup.mixed(),
  enableConversation: yup.boolean(),
  eventPlace: yup.string().required("Ова поле е задолжително"),
  eventPlaceCoordinates: yup.string().required("Ова поле е задолжително"),
  maxParticipants: yup.number(),
  numParticipants: yup.number(),
  online: yup.boolean(),
});

const startValues = {
  title: "",
  description: "",
  link: "",
  startDate: moment(),
  endDate: moment(),
  featuredImage: "",
  enableConversation: true,
  eventPlace: "",
  eventPlaceCoordinates: "",
  eventType: "",
};

const eventmessageStartValues = {
  message: "",
};

const CreateEvent = ({
  createEvent,
  updateEvent,
  fetchEvent,
  eventDetails,
  listEventmessages,
  createEventmessage,
  updateEventmessage,
  deleteEventmessage,
  eventmessageList,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);

  const {
    eventData,
    isFetching: eventDataIsFetching,
    isFetched: eventDataIsFetched,
  } = eventDetails;

  let { id } = useParams();
  const history = useHistory();
  const [eventmessageInitialValues, setEventmessageInitialValues] = useState({
    ...eventmessageStartValues,
    event: id,
  });

  const {
    listData: eventmessageListData,
    isFetching: eventmessageListFetching,
    isFetched: eventmessageListFetched,
  } = eventmessageList;
  


  const eventmessages = eventmessageList.listData;
  const [file, setFile] = useState(null);

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  useEffect(() => {
    fetchEvent(id);
    listEventmessages({
      event: id,
    });
  }, []);
  
  const formSubmit = async (values) => {
    try {
      // Convert start and end dates to ISOString format
      values.startDate = moment(values.startDate).toISOString();
      values.endDate = moment(values.endDate).toISOString();
      
      let event = null;
      if (id) {
        event = await updateEvent(id, file, values);
      } else {
        event = await createEvent(file, values);
        // alert("The event is successfully created");
      }
      history.push("/event");
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };  

  const applyMessage = async (values) => {
    let eventmessage = null;
    try {
      eventmessage = await createEventmessage(file, values);
      listEventmessages({ event: id });
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (eventData) {
      setInitialValues({
        title: eventData.title || "",
        description: eventData.description || "",
        link: eventData.link || "",
        startDate: eventData.startDate ? moment(eventData.startDate).format("YYYY-MM-DDTHH:mm") : moment(),
        endDate: eventData.endDate ? moment(eventData.endDate).format("YYYY-MM-DDTHH:mm") : moment(),
        featuredImage: eventData.featuredImage || "",
        enableConversation: eventData.enableConversation || false,
        eventPlace: eventData.eventPlace || "",
        eventPlaceCoordinates: eventData.eventPlaceCoordinates || "",
        online: eventData.online || false
      });
    }
  }, [eventData]);  

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container w-2/4 m-0 mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">Додади настан</div>
        <Formik
          initialValues={initialValues}
          validationSchema={EventSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          <Form>
            <div className="">
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="title" className="text-gray-700">
                    Наслов
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="title"
                    placeholder="Внесете наслов"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="title" />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <label htmlFor="description" className="text-gray-700 mt-10">
                  Текст
                  <span className="text-red required-dot">*</span>
                </label>
                  <Field
                    name="description"
                    component="textarea"
                    rows="10"
                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="description" />
                  </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="startDate" className="text-gray-700">
                    Почетен датум и време
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="startDate"
                    type="datetime-local"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  />
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="startDate" />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="endDate" className="text-gray-700">
                    Краен датум и време
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="endDate"
                    type="datetime-local"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  />
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="endDate" />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <label htmlFor="featuredImage" className="text-gray-700 mt-10">
                  Фотографија
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAttachment}
                  className="text-center text-base px-20 py-3 mt-5 w-4/5 md:w-6/12"
                />
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="link" className="text-gray-700">
                    Линк
                  </label>
                  <Field
                    name="link"
                    placeholder="Внесете линк"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="eventPlace" className="text-gray-700">
                    Локација
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="eventPlace"
                    placeholder="Внесете локација"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="eventPlaceCoordinates" className="text-gray-700">
                    Координати на локација
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="eventPlaceCoordinates"
                    placeholder="Внесете координати на локација"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm"
                  ></Field>
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="enableConversation" className="text-gray-700">
                    Овозможи дискусија: 
                  </label>
                  <Field
                    name="enableConversation"
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <label htmlFor="online" className="text-gray-700">
                    Онлајн: 
                  </label>
                  <Field
                    name="online"
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                </div>
              </div>
              <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
                <button
                  type="submit"
                  className="py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Зачувај
                </button>
              </div>
            </div>
          </Form>
        </Formik>

        <div className="mt-5">
          <div className="text-gray-700">
            Избери предефиниран коментар од листата
          </div>
          <Formik
            initialValues={eventmessageInitialValues}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={applyMessage}
          >
            {(props) => (
              <Form>
                <Field
                  name="message"
                  component="textarea"
                  rows="4"
                  placeholder="Внесете дополнителен коментар тука"
                  className="mt-5 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
                <button
                  type="submit"
                  className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Испрати порака
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-10 mb-40">
          <div className="text-2xl mb-3">Листа на пораки</div>
          <EventMessageList messages={eventmessages} deleteMessage={deleteEventmessage} />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: getEventDetails(state),
  eventmessageList: getEventmessages(state),
  eventmessageDetails: getEventmessageDetails(state),
});

const mapDispatchToProps = {
  createEvent,
  fetchEvent,
  updateEvent,
  listEventmessages,
  createEventmessage,
  deleteEventmessage,
  updateEventmessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
