import React, {useCallback, useEffect, useState} from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { listCategories } from "../../actions/category";
import { Bar } from "react-chartjs-2";

const StatisticsByCategory = ({countIssues, listCategories}) => {
  const [categories, setCategories] = useState([]);

  const state = {
    labels: categories.map((x) => x.title),
    datasets: [
      {
        backgroundColor: "#00408A",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: categories.map((x) => x.reportsCount),
      },
    ],
  };

  useEffect(() => {
    statsByCategory();
  }, []);

  const countCategory = useCallback(async (categoryId) => {
    let count = await countIssues({ "right_category.id": categoryId });
    return count;
  }, []);

  const statsByCategory = async () => {
    let categories = await listCategories();

    let promises = [];
    for (let i = 0; i < categories.length; ++i) {
      promises.push(countCategory(categories[i].id));
    }
    const counts = await Promise.all(promises);
    for (let i = 0; i < categories.length; ++i) {
      categories[i].reportsCount = counts[i];
    }
    setCategories(categories);
  };
  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">Статистики - прекршено право</div>
        <Bar
          data={state}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
  listCategories
};

export default connect(null, mapDispatchToProps)(StatisticsByCategory);

