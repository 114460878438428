import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import avatar from "../../img/nophoto.jpg";

const EventmessageList = ({ messages, deleteMessage, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="inline-block min-w-full shadow rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Реден бр.
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Порака
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Објавено на
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Акција
                </th>
              </tr>
            </thead>
            <tbody>
              {messages.map((item, idx) => {
                return (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {idx + 1}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.message}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {moment(item.updated_at).format("DD.MM.YYYY h:mm a")}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        className="text-red hover:text-red"
                        onClick={() => deleteMessage(item.id)}
                      >
                        Избриши
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EventmessageList;
