import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { listMunicipalities } from "../../actions/municipality";
import { Bar } from "react-chartjs-2";

const StatisticsByCity = ({ countIssues, listMunicipalities }) => {
  const [municipalities, setMunicipalities] = useState([]);

  const state = {
    labels: municipalities.map((x) => x.name),
    datasets: [
      {
        backgroundColor: "#00408A",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: municipalities.map((x) => x.reportsCount),
      },
    ],
  };

  useEffect(() => {
    statsByMunicipality();
  }, []);

  const countMunicipality = useCallback(async (municipalityId) => {
    let count = await countIssues({ "municipality.id": municipalityId });
    return count;
  }, []);

  const statsByMunicipality = async () => {
    let municipalities = await listMunicipalities();

    let promises = [];
    for (let i = 0; i < municipalities.length; ++i) {
      promises.push(countMunicipality(municipalities[i].id));
    }
    const counts = await Promise.all(promises);
    for (let i = 0; i < municipalities.length; ++i) {
      municipalities[i].reportsCount = counts[i];
    }
    setMunicipalities(municipalities);
  };

  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Статистики - место
        </div>
        <Bar
          data={state}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
  listMunicipalities,
};

export default connect(null, mapDispatchToProps)(StatisticsByCity);
