import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { Doughnut } from "react-chartjs-2";

const StatisticsByReportStatus = ({ countIssues }) => {
  const [reportStatusCounts, setReportStatusCounts] = useState([0, 0, 0]);
  const state = {
    labels: ["Отворени", "Во процес", "Затворени"],
    datasets: [
      {
        backgroundColor: ["#00408A", "#db3e3e", "#20ac20"],
        hoverBackgroundColor: ["#002857", "#c22424", "#20ac20"],
        data: reportStatusCounts,
      },
    ],
  };

  useEffect(() => {
    statsByReportStatus();
  }, []);

  const statsByReportStatus = async () => {
    let open = await countIssues({ status: "reported" });
    let inProgress = await countIssues({ status: "in_progress" });
    let closed = await countIssues({ status: "closed" });
    setReportStatusCounts([open, inProgress, closed]);
  };

  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Статистики - статус на пријави
        </div>
        <Doughnut
          data={state}
          options={{
            legend: {
              display: true,
              position: "top",
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
};

export default connect(null, mapDispatchToProps)(StatisticsByReportStatus);
