import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getProfile } from "../actions/user";
import Header from "../components/Header";
import Layout from "../Layout";
import { getUserDetails } from "../selectors/user";
import BgImage from "../components/BgImage";

const Profile = ({ getProfile, userDetails }) => {
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-center text-2xl leading-tight mt-20 mb-20 uppercase">
          Профил
        </div>
        <div className="py-8">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Реден број
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Име
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Презиме
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Е-маил адреса
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Креиран на
                  </th>
                </tr>
              </thead>
              <tbody>
                {userDetails.userData && (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {userDetails.userData.id}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {userDetails.userData.firstName}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {userDetails.userData.familyName}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {userDetails.userData.email}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {moment(userDetails.userData.updated_at).format(
                        "DD.MM.YYYY"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
