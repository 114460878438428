import getAxiosInstance from '../config/http';

export const CREATE_ADVICE_REQUEST = 'ADVICE@CREATE_ADVICE_REQUEST';
export const CREATE_ADVICE_SUCCESS = 'ADVICE@CREATE_ADVICE_SUCCESS';
export const CREATE_ADVICE_FAILURE = 'ADVICE@CREATE_ADVICE_FAILURE';

export const createAdvice = (file, adviceData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_ADVICE_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(adviceData));
    const { data } = await http.post("/advice-items", formData);
    dispatch({
      type: CREATE_ADVICE_SUCCESS,
      payload: {
        adviceData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_ADVICE_FAILURE,
    });
    throw e;
  }
};

export const LIST_ADVICE_REQUEST = 'ADVICE@LIST_ADVICE_REQUEST';
export const LIST_ADVICE_SUCCESS = 'ADVICE@LIST_ADVICE_SUCCESS';
export const LIST_ADVICE_FAILURE = 'ADVICE@LIST_ADVICE_FAILURE';

export const listAdvice = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ADVICE_REQUEST,
  });
  try{
    const adviceData = await http.get('/advice-items', {params});
    dispatch({
      type: LIST_ADVICE_SUCCESS,
      payload: {
        listData: adviceData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_ADVICE_FAILURE,
    });
  }
};

export const FETCH_ADVICE_REQUEST = 'ADVICE@FETCH_ADVICE_REQUEST';
export const FETCH_ADVICE_SUCCESS = 'ADVICE@FETCH_ADVICE_SUCCESS';
export const FETCH_ADVICE_FAILURE = 'ADVICE@FETCH_ADVICE_FAILURE';

export const fetchAdvice = adviceId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ADVICE_REQUEST,
  });
  try{
    const adviceData = await http.get(`/advice-items/${adviceId}`);
    dispatch({
      type: FETCH_ADVICE_SUCCESS,
      payload: {
        adviceData: adviceData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_ADVICE_FAILURE,
    });
  };
};

export const UPDATE_ADVICE_REQUEST = 'ADVICE@UPDATE_ADVICE_REQUEST';
export const UPDATE_ADVICE_SUCCESS = 'ADVICE@UPDATE_ADVICE_SUCCESS';
export const UPDATE_ADVICE_FAILURE = 'ADVICE@UPDATE_ADVICE_FAILURE';

export const updateAdvice = (id, file, adviceData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_ADVICE_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(adviceData));
    const { data } = await http.put(`/advice-items/${id}`, formData, adviceData);
    dispatch({
      type: UPDATE_ADVICE_SUCCESS,
      payload: {
        adviceData: data,
      },
    });
    return data;
  } catch(e){
    dispatch({
      type: UPDATE_ADVICE_FAILURE,
    });
  };
};

export const DELETE_ADVICE_REQUEST = 'ADVICE@DELETE_ADVICE_REQUEST';
export const DELETE_ADVICE_SUCCESS = 'ADVICE@DELETE_ADVICE_SUCCESS';
export const DELETE_ADVICE_FAILURE = 'ADVICE@DELETE_ADVICE_FAILURE';

export const deleteAdvice = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ADVICE_REQUEST,
  });
  try{
    const data = await http.delete(`/advice-items/${id}`);
    dispatch({
      type: DELETE_ADVICE_SUCCESS,
      payload: {
        adviceId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_ADVICE_FAILURE,
    });
  };
};
