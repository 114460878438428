import React from "react";
import { Link } from "react-router-dom";

//Components
import Header from "../components/Header";
import Layout from "../Layout";
import BgImage from "../components/BgImage";

//Assets
import home from "../img/icons/home.svg";
import issues from "../img/icons/reports.svg";
import news from "../img/icons/news.svg";
import advice from "../img/icons/advice.svg";
import event from "../img/icons/advice.svg";
import admins from "../img/icons/admins.svg";
import stats from "../img/icons/stats.svg";
import bg from "../img/bg-2.png";

const items = [
  {
    title: "Почетна",
    icon: home,
    path: "/dashboard",
    description: "Почетна страница",
  },
  {
    title: "Пријави",
    icon: issues,
    path: "/issues",
    description: "На оваа страница може да видите листа од пријави",
  },
  {
    title: "Новости",
    icon: news,
    path: "/posts",
    description: "На оваа страница може да видите листа од новости",
  },
  {
    title: "Совети",
    icon: advice,
    path: "/advice",
    description:
      "На оваа страница може да видите листа од совети, да ажурирате податоци и да избришете совети",
  },
  {
    title: "Настани",
    icon: event,
    path: "/event",
    description:
      "На оваа страница може да видите листа од настани, да ажурирате податоци и да избришете настани",
  },
  {
    title: "Администратори",
    icon: admins,
    path: "/administrators",
    description: "На оваа страница може да видите листа од администратори",
  },
  {
    title: "Статистики",
    icon: stats,
    path: "/statistics",
    description:
      "На оваа страница може да видите статистички податоци за пријавите",
  },
];

const Dashboard = () => {
  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto mt-20">
          <div className="grid grid-cols-3 gap-6">
            {items.map((item, idx) => {
              return (
                <Link to={item.path}>
                  <div className="shadow-lg rounded-2xl p-4 bg-white h-64">
                    <div className="flex flex-col items-center justify-center h-full">
                      <img src={item.icon} className="w-16 h-16" />
                      <p className="text-custom text-xl font-medium mb-4 mt-4">
                        {item.title}
                      </p>
                      <p className="text-gray-500 text-base text-center">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
    </Layout>
  );
};
export default Dashboard;
