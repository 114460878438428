import React, { useEffect } from "react";
import Header from "../../components/Header";
import PostList from "../../components/Post/PostList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";
import { getPosts } from "../../selectors/post";
import { listPosts, deletePost } from "../../actions/post";
import add from "../../img/add.svg";

const PostListPage = ({ listPosts, updatePost, deletePost, postsList }) => {
  const { isFetching, isFetched, listData } = postsList;
  const {
    listData: postsListData,
    isFetching: postsListFetching,
    isFetched: postsListFetched,
  } = postsList;

  useEffect(() => {
    listPosts({ '_sort': 'updated_at:DESC' });
  }, []);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <h2 className="text-center text-2xl uppercase leading-tight mt-10 mb-10">
          Новости
        </h2>
        <div className="text-right">
          <div className="flex justify-end">
            <Link
              to="/createPost"
              className="flex flex-row align-items align-middle items-center justify-end"
            >
              <div className="mr-4 text-blue">Додади новост</div>
              <img
                src={add}
                className="rounded-full bg-blue w-10 h-10 p-3"
                alt="Add button"
              />
            </Link>
          </div>
        </div>
        <PostList posts={postsListData} deletePost={deletePost} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  postsList: getPosts(state),
});

const mapDispatchToProps = {
  listPosts,
  deletePost,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostListPage);
