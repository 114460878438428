import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  fetchInternalReport,
  updateInternalReport,
} from "../../actions/internalReport";
import { listDtiLocations } from "../../actions/dtiLocation";
import { fetchIssue } from "../../actions/issue";
import { getIssueDetails } from "../../selectors/issue";
import { getInternalReportDetails } from "../../selectors/internalReport";
import { getUserDetails } from "../../selectors/user";
import {
  getInternalComments,
  getInternalCommentDetails,
} from "../../selectors/internalComment";
import {
  listInternalComments,
  createInternalComment,
  updateInternalComment,
  deleteInternalComment,
} from "../../actions/internalComment";
import Header from "../../components/Header";
import Layout from "../../Layout";
import avatar from "../../img/nophoto.jpg";
import InternalCommentList from "./InternalCommentList";
import { getDtiLocations } from "../../selectors/dtiLocation";
import { listUsers } from "../../actions/user";
import { getUsers } from "../../selectors/user";
import _filter from "lodash/filter";
import * as yup from "yup";
import { values } from "lodash";

const startValues = {
  status: "",
};

const internalReportValues = {
  comment: "",
};

const commentStartValues = {
  content: "",
  link: "",
};

const assignmentSchema = yup.object().shape({
  assigned_regional_inspector: yup
    .number()
    .nullable(true)
    .when("assigned_subinspector", {
      is: (val) => !!val,
      then: yup
        .number()
        .required(
          "Не смее да има зададено подинспектор без одбран регионален инспектор"
        ),
    }),
  assigned_subinspector: yup.number().nullable(true),
});

const InternalReportDetails = ({
  fetchInternalReport,
  updateInternalReport,
  internalReportDetails,
  getInternalReportDetails,
  listInternalComments,
  createInternalComment,
  fetchInternalComment,
  updateInternalComment,
  deleteInternalComment,
  fetchIssue,
  issueDetails,
  commentList,
  listDtiLocations,
  locationsList,
  listUsers,
  users,
  userDetails,
}) => {
  let { id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(startValues);
  const [commentInitialValues, setCommentInitialValues] = useState({
    ...commentStartValues,
    internal_report: id,
  });
  const [internalReportInitialValues, setInternalReportInitialValues] =
    useState(internalReportValues);

  const [
    inspectorAssignementInitialValues,
    setInspectorAssignmentInitialValues,
  ] = useState({
    assigned_regional_inspector: null,
    assigned_subinspector: null,
  });

  const {
    listData: commentListData,
    isFetching: commentListFetching,
    isFetched: commentListFetched,
  } = commentList;

  const {
    userData,
    isFetching: userDataIsFetching,
    isFetched: userDataIsFetched,
  } = userDetails;

  const comments = commentList.listData;

  const [file, setFile] = useState(null);

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  const fetch = useCallback(async () => {
    listDtiLocations();
    listInternalComments({
      "internal_report.id": id,
    });
    const internalReport = await fetchInternalReport(id);
    fetchIssue(internalReport.report.id);
    setInspectorAssignmentInitialValues({
      assigned_regional_inspector: internalReport.assigned_regional_inspector
        ? internalReport.assigned_regional_inspector.id
        : null,
      assigned_subinspector: internalReport.assigned_subinspector
        ? internalReport.assigned_subinspector.id
        : null,
      status: internalReport.status,
    });
  }, [id]);

  useEffect(() => {
    fetch();
  }, []);

  let reportType = "";
  if (internalReportDetails.reportData) {
    let type = internalReportDetails.reportData.report.reportType;
    if (type === "request") {
      reportType = "Барање";
    } else if (type === "complaint") {
      reportType = "Жалба";
    } else {
      reportType = "Прекршок";
    }
  }

  let reportStatus = "";
  if (internalReportDetails.reportData) {
    let status = internalReportDetails.reportData.report.status;
    if (status === "reported") {
      reportStatus = "Отворена";
    } else if ((status = "in_progress")) {
      reportStatus = "Во процедура";
    } else {
      reportStatus = "Затворена";
    }
  }

  let roleType = "";
  if (userDetails.isFetched && userDetails.userData) {
    roleType = userDetails.userData.role.type;
  }

  const locationChanged = async (e) => {
    await listUsers({
      "dti_locations.id": e.target.value,
    });
  };

  let details = internalReportDetails.reportData;
  let originalIssueDetails = issueDetails.issueData;

  const print = () => {
    window.print();
  };

  const assignInspectors = async (values) => {
    // let newStatus = "REPORTED";
    // if (!!values.assigned_regional_inspector) {
    //   newStatus = "ASSIGNED_REGIONAL";
    // }
    // if (!!values.assigned_subinspector) {
    //   newStatus = "ASSIGNED_SUBINSPECTOR";
    // }
    console.log(values);
    let newStatus = null;
    if(roleType === "regional_inspector" && values.assigned_subinspector){
      newStatus = "ASSIGNED_SUBINSPECTOR";
    }
    // else if(roleType === "subinspector" && values.assigned_subinspector){
    //   newStatus = "ASSIGNED_SUBINSPECTOR";
    // }
    // else {
    //   newStatus = "REPORTED";
    // }
    try {
      updateInternalReport(id, {
        status: newStatus || values.status,
        assigned_regional_inspector: values.assigned_regional_inspector,
        assigned_subinspector: values.assigned_subinspector,
      });
      alert('Промената е успешно направена.');
      history.push("/internalReports");
    } catch (e){
      console.log(e);
    }

  };

  const handleChangeStatus = (e) => {
    updateInternalReport(id, { status: e.target.value });
    // alert("Статусот на пријавата е променет");
    // history.push("/issues");
  };

  const applyComment = async (values) => {
    let comment = null;
    try {
      comment = await createInternalComment(file, values);
      listInternalComments({ internal_report: id });
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  const returnToRegional = async (values) => {
    console.log("return to regional");
    try {
      const internalReport = await fetchInternalReport(id);
      updateInternalReport(id, { status: "RETURNED_REGIONAL" });
      alert("Промената е успешно направена");
      history.push("/internalReports");
    } catch (e) {
      console.log(e);
    }
  };

  const returnToSSM = async () => {
    console.log('return to SSM');
    try {
      const internalReport = await fetchInternalReport(id);
      updateInternalReport(id, { status: "RETURNED_SSM" });
      alert("Промената е успешно направена");
      history.push("/internalReports");
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Layout>
      <Header></Header>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Детали за пријава од ССМ
        </div>
        <div className="flex flex-row justify-end mt-10 printHide">
          <button
            type="button"
            onClick={() => print()}
            className="py-2 px-4 bg-red text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
          >
            Принтај пријава
          </button>
        </div>
        <div className="shadow rounded-lg">
          <table className="mt-10 min-w-full leading-normal">
            <thead></thead>
            <tbody>
              {details && originalIssueDetails && (
                <>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Тип на пријава
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {reportType}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Статус на пријава
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {reportStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      За компанија
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {details.report.company}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Опис
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {details.report.description}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Дејност
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {originalIssueDetails.industry.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Општина
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {originalIssueDetails.municipality.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Прекршено право
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {originalIssueDetails.right_category.title}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Документи
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {details.report.files.map((file) => {
                        return file.mime.startsWith("image/") ? (
                          <a
                            href={
                              details.report.files.length === 0
                                ? "#"
                                : `${process.env.REACT_APP_API_URL}${file.url}`
                            }
                          >
                            <img
                              alt=""
                              className="w-20 h-20"
                              src={
                                details.report.files.length === 0
                                  ? avatar
                                  : `${process.env.REACT_APP_API_URL}${file.url}`
                              }
                            />
                          </a>
                        ) : (
                          <a
                            className="text-blue"
                            href={`${process.env.REACT_APP_API_URL}${file.url}`}
                          >{`${file.name}`}</a>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Време на ажурирање на пријавата
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {moment(details.report.updated_at).format("DD.MM.YYYY")}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        {details && roleType !== "subinspector" && (
          <div className="mt-20 printHide">
            <div className="text-2xl mb-3">Одговорни лица</div>
            <Formik
              onSubmit={assignInspectors}
              initialValues={inspectorAssignementInitialValues}
              validationSchema={assignmentSchema}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  Локација
                  <select
                    className="mt-2 rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                    onChange={locationChanged}
                  >
                    <option value=""></option>
                    {locationsList.listData.map((location) => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                  </select>
                  {details && roleType === "dip_admin" && (
                    <>
                      <p>Избери регионален инспектор</p>
                      <Field
                        name="assigned_regional_inspector"
                        as="select"
                        className="mt-2 rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                      >
                        {details.assigned_regional_inspector ? (
                          <option
                            value={details.assigned_regional_inspector.id}
                          >{`${details.assigned_regional_inspector.firstName} ${details.assigned_regional_inspector.familyName}`}</option>
                        ) : (
                          <option value=""></option>
                        )}
                        {_filter(users.listData, {
                          role: { type: "regional_inspector" },
                        }).map((user) => (
                          <option
                            value={user.id}
                          >{`${user.firstName} ${user.familyName}`}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="assigned_regional_inspector" />
                    </>
                  )}
                  <p>Избери подинспектор</p>
                  <Field
                    name="assigned_subinspector"
                    as="select"
                    className="mt-2 rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  >
                    {details.assigned_subinspector ? (
                      <option
                        value={details.assigned_subinspector.id}
                      >{`${details.assigned_subinspector.firstName} ${details.assigned_subinspector.familyName}`}</option>
                    ) : (
                      <option></option>
                    )}
                    {_filter(users.listData, {
                      role: { type: "subinspector" },
                    }).map((user) => (
                      <option
                        value={user.id}
                      >{`${user.firstName} ${user.familyName}`}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="assigned_subinspector" />
                  {details && roleType === "dip_admin" && (
                    <>
                      <p>Статус на пријава</p>
                      <Field
                        name="status"
                        as="select"
                        className="mt-2 rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                      >
                        <option value="REPORTED">Пријавено</option>
                        <option value="ASSIGNED_REGIONAL">
                          Доделено на регионален инспектор
                        </option>
                        <option value="ASSIGNED_SUBINSPECTOR">
                          Доделено на подинспектор
                        </option>
                        <option value="RETURNED_REGIONAL">
                          Вратено од регионален инспектор
                        </option>
                        <option value="RETURNED_SSM">Вратено до ССМ</option>
                      </Field>
                    </>
                  )}
                  <button
                    type="submit"
                    className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                  >
                    Задади
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {details &&
          roleType === "subinspector" &&
          details.status === "ASSIGNED_SUBINSPECTOR" && (
            <button
              type="submit"
              onClick={returnToRegional}
              className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
            >
              Означи завршено и врати на регионален инспектор
            </button>
          )}
          {details &&
          roleType === "regional_inspector" && (
            <button
              type="submit"
              onClick={returnToSSM}
              className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
            >
              Означи завршено и врати на ССМ
            </button>
          )}
        <div className="mt-20 printHide">
          <div className="text-2xl">Комуникација</div>
        </div>
        <div className="mt-5">
          <Formik
            initialValues={commentInitialValues}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={applyComment}
          >
            {(props) => (
              <Form>
                <Field
                  name="content"
                  component="textarea"
                  rows="4"
                  placeholder="Внесете дополнителен коментар тука"
                  className="mt-5 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
                <label htmlFor="link" className="text-gray-700">
                  Линк
                </label>
                <Field
                  name="link"
                  type="text"
                  placeholder="Внесете линк тука"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
                <div className="mt-5">
                  <label htmlFor="files" className="text-gray-700">
                    Прикачи фотографија/документ
                  </label>
                  <input
                    type="file"
                    accept="*"
                    onChange={handleAttachment}
                    className="text-center text-base px-20 py-3 w-4/5 md:w-6/12"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Испрати коментар
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-10 mb-40">
          <div className="text-2xl mb-3">Листа на коментари</div>
          <InternalCommentList
            comments={comments}
            deleteInternalComment={deleteInternalComment}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  internalReportDetails: getInternalReportDetails(state),
  issueDetails: getIssueDetails(state),
  commentList: getInternalComments(state),
  commentDetails: getInternalCommentDetails(state),
  locationsList: getDtiLocations(state),
  users: getUsers(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchInternalReport,
  updateInternalReport,
  listInternalComments,
  createInternalComment,
  deleteInternalComment,
  updateInternalComment,
  listDtiLocations,
  fetchIssue,
  listUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalReportDetails);
