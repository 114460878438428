import getAxiosInstance from '../config/http';

export const CREATE_EVENTMESSAGE_REQUEST = "EVENTMESSAGE@CREATE_EVENTMESSAGE_REQUEST";
export const CREATE_EVENTMESSAGE_SUCCESS = "EVENTMESSAGE@CREATE_EVENTMESSAGE_SUCCESS";
export const CREATE_EVENTMESSAGE_FAILURE = "EVENTMESSAGE@CREATE_EVENTMESSAGE_FAILURE";

export const createEventmessage = (file, eventmessageData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_EVENTMESSAGE_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(eventmessageData));
    console.log(eventmessageData);
    const { data } = await http.post(
      '/event-messages',
      formData
    );
    dispatch({
      type: CREATE_EVENTMESSAGE_SUCCESS,
      payload: {
        eventmessageData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_EVENTMESSAGE_FAILURE,
    });
    throw e;
  }
};

export const LIST_EVENTMESSAGE_REQUEST = 'EVENTMESSAGE@LIST_EVENTMESSAGE_REQUEST';
export const LIST_EVENTMESSAGE_SUCCESS = 'EVENTMESSAGE@LIST_EVENTMESSAGE_SUCCESS';
export const LIST_EVENTMESSAGE_FAILURE = 'EVENTMESSAGE@LIST_EVENTMESSAGE_FAILURE';

export const listEventmessages = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_EVENTMESSAGE_REQUEST,
  });
  try{
    const eventmessageData = await http.get('/event-messages', {params});
    dispatch({
      type: LIST_EVENTMESSAGE_SUCCESS,
      payload: {
        listData: eventmessageData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_EVENTMESSAGE_FAILURE,
    });
  }
};

export const FETCH_EVENTMESSAGE_REQUEST = 'EVENTMESSAGE@FETCH_EVENTMESSAGE_REQUEST';
export const FETCH_EVENTMESSAGE_SUCCESS = 'EVENTMESSAGE@FETCH_EVENTMESSAGE_SUCCESS';
export const FETCH_EVENTMESSAGE_FAILURE = 'EVENTMESSAGE@FETCH_EVENTMESSAGE_FAILURE';

export const fetchEventmessage = eventmessageId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_EVENTMESSAGE_REQUEST,
  });
  try{
    const eventmessageData = await http.get(`/event-messages/${eventmessageId}`);
    dispatch({
      type: FETCH_EVENTMESSAGE_SUCCESS,
      payload: {
        eventmessageData: eventmessageData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_EVENTMESSAGE_FAILURE,
    });
  };
};

export const UPDATE_EVENTMESSAGE_REQUEST = 'EVENTMESSAGE@UPDATE_EVENTMESSAGE_REQUEST';
export const UPDATE_EVENTMESSAGE_SUCCESS = 'EVENTMESSAGE@UPDATE_EVENTMESSAGE_SUCCESS';
export const UPDATE_EVENTMESSAGE_FAILURE = 'EVENTMESSAGE@UPDATE_EVENTMESSAGE_FAILURE';

export const updateEventmessage = (id, eventmessageData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_EVENTMESSAGE_REQUEST,
  });
  try{
    const data = await http.put(`/event-messages/${id}`, eventmessageData);
    dispatch({
      type: UPDATE_EVENTMESSAGE_SUCCESS,
      payload: {
        eventmessageData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_EVENTMESSAGE_FAILURE,
    });
  };
};

export const DELETE_EVENTMESSAGE_REQUEST = 'EVENTMESSAGE@DELETE_EVENTMESSAGE_REQUEST';
export const DELETE_EVENTMESSAGE_SUCCESS = 'EVENTMESSAGE@DELETE_EVENTMESSAGE_SUCCESS';
export const DELETE_EVENTMESSAGE_FAILURE = 'EVENTMESSAGE@DELETE_EVENTMESSAGE_FAILURE';

export const deleteEventmessage = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_EVENTMESSAGE_REQUEST,
  });
  try{
    const data = await http.delete(`/event-messages/${id}`);
    dispatch({
      type: DELETE_EVENTMESSAGE_SUCCESS,
      payload: {
        eventmessageId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_EVENTMESSAGE_FAILURE,
    });
  };
};
