import getAxiosInstance from '../config/http';

export const CREATE_EVENT_REQUEST = 'EVENT@CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'EVENT@CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'EVENT@CREATE_EVENT_FAILURE';

export const createEvent = (file, eventData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_EVENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(eventData));
    const { data } = await http.post("/events", formData);
    dispatch({
      type: CREATE_EVENT_SUCCESS,
      payload: {
        eventData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_EVENT_FAILURE,
    });
    throw e;
  }
};

export const LIST_EVENT_REQUEST = 'EVENT@LIST_EVENT_REQUEST';
export const LIST_EVENT_SUCCESS = 'EVENT@LIST_EVENT_SUCCESS';
export const LIST_EVENT_FAILURE = 'EVENT@LIST_EVENT_FAILURE';

export const listEvent = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_EVENT_REQUEST,
  });
  try{
    const eventData = await http.get('/events', {params});
    dispatch({
      type: LIST_EVENT_SUCCESS,
      payload: {
        listData: eventData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_EVENT_FAILURE,
    });
  }
};

export const FETCH_EVENT_REQUEST = 'EVENT@FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'EVENT@FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'EVENT@FETCH_EVENT_FAILURE';

export const fetchEvent = eventId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_EVENT_REQUEST,
  });
  try{
    const eventData = await http.get(`/events/${eventId}`);
    dispatch({
      type: FETCH_EVENT_SUCCESS,
      payload: {
        eventData: eventData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_EVENT_FAILURE,
    });
  };
};

export const UPDATE_EVENT_REQUEST = 'EVENT@UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'EVENT@UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'EVENT@UPDATE_EVENT_FAILURE';

export const updateEvent = (id, file, eventData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_EVENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(eventData));
    const { data } = await http.put(`/events/${id}`, formData, eventData);
    dispatch({
      type: UPDATE_EVENT_SUCCESS,
      payload: {
        eventData: data,
      },
    });
    return data;
  } catch(e){
    dispatch({
      type: UPDATE_EVENT_FAILURE,
    });
  };
};

export const DELETE_EVENT_REQUEST = 'EVENT@DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'EVENT@DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'EVENT@DELETE_EVENT_FAILURE';

export const deleteEvent = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_EVENT_REQUEST,
  });
  try{
    const data = await http.delete(`/events/${id}`);
    dispatch({
      type: DELETE_EVENT_SUCCESS,
      payload: {
        eventId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_EVENT_FAILURE,
    });
  };
};
