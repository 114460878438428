import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const IssueList = ({ issues, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="py-8">
          <div className="shadow rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Реден бр.
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Пријава
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Пријавeно
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Компанија
                  </th>

                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Дејност
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Прекршено право
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Статус
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Ажурирано на
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Акција
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {issues.map((item, idx) => {
                  console.log(item);
                  let reportType = "";
                  let type = item.reportType;
                  if (type === "request") {
                    reportType = "Барање";
                  } else if (type === "complaint") {
                    reportType = "Жалба";
                  } else {
                    reportType = "Прекршок";
                  }

                  let reportStatus = "";
                  let status = item.status;
                  if (status === "reported") {
                    reportStatus = "Отворена";
                  } else if (status === "in_progress") {
                    reportStatus = "Во процедура";
                  } else {
                    reportStatus = "Затворена";
                  }
                  return (
                    <tr {...props}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {idx + 1}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportType}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.selfReport === true ? "За себе" : "За друг"}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.company}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.industry.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.right_category.title}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportStatus}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {moment(item.updated_at).format("DD.MM.YYYY")}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link
                          to={`/issueDetails/${item.id}`}
                          className="text-blue"
                        >
                          Види детали
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueList;
