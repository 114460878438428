import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import {resetPassword} from "../actions/user";
import { useLocation } from "react-router";

const startValues = {
  password: "",
  passwordConfirmation: "",
};


const ResetPassword = ({resetPassword}) => {
  const [initialValues, setInitialValues] = useState(startValues);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  console.log(params.get("code"));
  console.log(location);

  const formSubmit = async (values) => {
    console.log(values);
    let pass = null;
    try {
      pass = await resetPassword({...values, code: params.get("code")});
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  return (
    <div className="container mx-auto">
      <div className="text-lg text-center">Ресетирај лозинка</div>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={formSubmit}
      >
        <Form>
          <div className="">
            <div className="mt-10">
              <div className="relative">
                <label htmlFor="title" className="text-gray-700">
                  Лозинка
                </label>
                <Field
                  name="password"
                  placeholder="Внесете лозинка"
                  type="password"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
              </div>
            </div>
            <div className="mt-10">
              <label htmlFor="content" className="text-gray-700 mt-10">
                Внеси повторно лозинка
              </label>
              <label className="text-gray-700" for="content">
                <Field
                  name="passwordConfirmation"
                  type="password"
                  className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                ></Field>
              </label>
            </div>
            <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
              <button
                type="submit"
                className="py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Промени лозинка
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};


const mapDispatchToProps = {
  resetPassword
};

export default connect(null, mapDispatchToProps)(ResetPassword);
