import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mx-auto">
        <h1>Политика за приватност</h1>
        <br />
        <p>Датум на влегување во сила: 21 Април 2021 година.</p>
        <br />
        <p>
          Сојузот на Синдикатите на Македонија – ССМ “( „ни“, „ние“ или „наше“)
          управува со мобилната апликација ССМ („Услугата“).
        </p>
        <br />
        <p>
          а оваа страница ќе најдете информации за собирањето, употребата и
          обелоденувањето на лични податоци кога ги користите нашите услуги и
          кои избори треба да ги поврзете со тие податоци.
        </p>
        <br />
        <p>
          Вашите податоци ги користиме за да ја обезбедиме и да ја подобриме
          Услугата. Со користење на Услугата, вие се согласувате информациите да
          се собираат и употребуваат во согласност со оваа политика.Освен ако не
          е поинаку утврдено во оваа Политика за приватност, поимите што се
          користат во оваа Политика за приватност го имаат истото значење како и
          во нашите Услови за користење.
        </p>
        <br />
        <h1>Зошто апликацијата користи лични податоци? Корисничка сметка </h1>
        <br />
        <p>
          За да можете да ги синхронизирате вашите податоци на неколку уреди и
          да ги користите услугите на апликацијата ССМ – Мои работнички права ,
          потребно е да креирате сметка.
        </p>
        <br />
        <p>
          Податоците за сметката се класифицираат како лични само ако можат да
          се користат за да ве идентификуваат.
        </p>
        <br />
        <p>Постојат два начина да креирате корисничка сметка:</p>
        <ul>
          <li>
            - со внесување на вашата адреса за е-пошта и создавање лозинка,
          </li>
          <li>
            - или со користење на деталите за најавување на Google, Facebook или
            Apple ID.
          </li>
        </ul>
        <p>
          Ако изберете да креирате сметка на Апликацијата ССМ – Мои работнички
          права со е-пошта, апликацијата ќе ја користи е-поштата дадена за да ја
          идентификува вашата корисничка сметка.
        </p>
        <br />
        <p>
          Ако изберете да креирате сметка за апликација Апликацијата ССМ – Мои
          работнички права со користење на деталите за најавување на Google,
          Facebook или Apple ID, апликацијата ќе испрати барање за врска до
          вашата сметка со барање да ја поврзе апликацијата ССМ – Мои работнички
          права и ќе ги собере адресата на е-пошта, името и фотографијата од
          поврзаната сметка. Во тој случај, Google, Facebook или Apple може да
          соберат податоци во врска со врската со вашата корисничка сметка на
          апликацијата ССМ – Мои работнички права. Врската со корисничката
          сметка на апликацијата ССМ – Мои работнички права ќе остане активна 30
          дена. Препорачуваме прво да ја прочитате политиката за приватност на
          Google, Facebook или Apple.
        </p>
        <br />
        <p>
          Апликацијата ССМ ги користи овие податоци единствено за да може да ве
          идентификува кога ќе се најавите на апликацијата. Вашите податоци нема
          да бидат предадени на трети лица под никакви околности.
        </p>
        <br />
        <h1>Собирање и употреба на информации</h1>
        <br />
        <p>
          Ние собираме неколку различни видови информации за разни цели за да ви
          ја обезбедиме и да ја подобриме нашата Услуга. Видови податоци што се
          собираат:
        </p>
        <br />
        <p>Лични податоци</p>
        <br />
        <p>
          Кога ја користите нашата Услуга, може да побараме од вас некои лични
          препознатливи информации што можат да се користат за контакт со вас
          или за да ве идентификуваме („лични податоци“).Личните препознатливи
          информации можат да вклучуваат, но не се ограничени на:
        </p>
        <br />
        <ul>
          <li>- Е-пошта</li>
          <li>- Име и презиме</li>
          <li>- Пол и возраст</li>
          <li>- Телефонски број</li>
        </ul>
        <p>Податоци за користење</p>
        <br />
        <p>
          Кога пристапувате до Услугата со и преку вашиот мобилен уред, ние може
          автоматски да пристапиме и собереме одредени информации, вклучувајќи,
          но не ограничувајќи се на видот на мобилен уред што го користите,
          единствениот ИД број на вашиот мобилен уред, IP адресата на вашиот
          мобилен уред, оперативниот систем на вашиот мобилен уред, галеријата
          со фотографии, видот на прелистувач што го користите, единствените
          идентификатори на уредот и други дијагностички податоци („податоци за
          користење“).
        </p>
        <br />
        <h1>Користење на податоци</h1>
        <br />
        <p>
          Сојузот на Синдикатите на Македонија ги користи собраните податоци за
          разни цели:
        </p>
        <br />
        <ul>
          <li>- за обезбедување и одржување на Услугата</li>
          <li>
            - за пријавување на прекшување на работнички права до надлежни
            институции
          </li>
          <li>- за да ве извести за промени во нашата Услуга</li>
          <li>
            - за да ви овозможи да учествувате во интерактивните опции на
            Услугата кога вие ќе сакате
          </li>
          <li>- за обезбеди грижа и поддршка за клиентите</li>
          <li>
            - за обезбеди анализа или вредни информации за да можеме да ја
            подобриме Услугата
          </li>
          <li>- за следење на користењето на Услугата</li>
          <li>- за откривање, спречување и решавање технички прашања</li>
        </ul>
        <br />
        <p>Пренос на податоци</p>
        <br />
        <p>
          Вашите информации, вклучувајќи ги личните податоци можат да бидат
          пренесени – и да се чуваат – на компјутери лоцирани надвор од вашата
          држава, регион или јуриздикција каде што законите за заштита на
          податоци можат да се разликуваат од оние во вашата јуриздикција.
          Доколку се наоѓате надвор од Македонија и одлучите да ни дадете
          информации, треба да знаете дека ги пренесуваме податоците,
          вклучувајќи ги и личните податоци, во Македонија и ги обработуваме
          таму.
        </p>
        <br />
        <p>
          Вашата согласност со Политиката за приватност заедно со вашето
          доставување на тие информации значи дека се согласувате со тој
          трансфер.
        </p>
        <br />
        <p>
          Сојузот на Синдикатите на Македонија ќе ги преземе сите разумни
          потребни чекори за да се осигури дека вашите податоци се безбедни и во
          согласност со оваа Политика за приватност и вашите лични податоци нема
          да се пренесуваат во организација или земја доколку нема соодветни
          контроли, вклучувајќи безбедност за вашите податоци и други лични
          информации.
        </p>
        <br />
        <h1>Обелоденување на податоци</h1>
        <br />
        <h1>Законски барања</h1>
        <br />
        <p>
          Сојузот на Синдикатите на Македонија може да ги обелодени вашите лични
          податоци верувајќи дека таквото дејство е неопходно за следново:
        </p>
        <br />
        <ul>
          <li>- да се постапи во согласност со законска обврска</li>
          <li>
            - да се заштитат и одбранат правата или сопственоста Сојузот на
            Синдикатите на Македонија
          </li>
          <li>
            - да се спречи или испита можно незаконско дело поврзано со услугата
          </li>
          <li>
            - да се заштити личната безбедност на корисниците на услугата или на
            јавноста
          </li>
          <li>- да се заштити од законска одговорност</li>
        </ul>
        <h1>Безбедност на податоци</h1>
        <br />
        <p>
          Безбедноста на вашите податоци ни е многу значајна, но запомнете дека
          ниту еден метод на пренос преку интернет, или метод на електронско
          чување не е 100% безбеден.Иако се стремиме да искористиме комерцијално
          прифатливи средства за заштита на вашите лични податоци, не може да
          гарантираме апсолутна безбедност.
        </p>
        <br />
        <h1>Даватели на услуги</h1>
        <br />
        <p>
          Може да ангажираме трети лица, друштва и поединци, за олеснување на
          нашата Услуга („Даватели на услуги“), што ја обезбедуваат Услугата во
          наше име, вршат услуги поврзани со нашата Услуга или ни помагаат за
          анализирање како се користи нашата Услуга. Овие трети лица имаат
          пристап до вашите лични податоци само за да ги извршат задачите во
          наше име и имаат обврска да не ги обелоденуваат или употребуваат
          податоците за други намени.
        </p>
        <br />
        <h1>Аналитика</h1>
        <br />
        <p>
          Апликацијата не користи статистички алатки за обработка на личните
          податоци
        </p>
        <br />
        <h1>Линкови до други страници</h1>
        <br />
        <p>
          Нашата Услуга може да содржи линкови до други страници со кои не
          управуваме ние. Доколку кликнете на линк на трето лице, ќе бидете
          насочени кон страницата на третото лице. Ве советуваме да ја
          погледнете Политиката за приватност на секоја страница што ја
          посетувате. Ние немаме контрола и не преземаме одговорност за
          содржината, политиките или практиките за приватност на страници или
          услуги на трети лица.
        </p>
        <br />
        <h1>Приватност на деца</h1>
        <br />
        <p>
          Нашата Услуга не се однесува на лица под 18-годишна возраст („Деца“).
          Ние свесно не собираме лични препознатливи информации за лица под
          18-годишна возраст.Доколку сте родител или старател и знаете дека
          вашите деца ни дале лични податоци, контактирајте нè.Доколку дознаеме
          дека сме собрале лични податоци од деца без верификација на
          родителската согласност, преземаме чекори да ги отстраниме тие
          информации од нашите сервери.
        </p>
        <br />
        <h1>Промени во оваа Политика за приватност</h1>
        <br />
        <p>
          Од време на време, може да ја ажурираме нашата Политика за
          приватност.Ќе ве известиме за сите измени преку објавување на новата
          Политика за приватност на оваа страница. Ќе ве известиме преку е-пошта
          и/или соопштение на нашата Услуга пред тие промени да влезат во сила и
          ќе го ажурираме „датумот на влегување во сила“ на почетокот на оваа
          Политика за приватност. Ве советуваме повремено да ја проверувате
          Политиката за приватност за измени.Промените на Политиката за
          приватност се важечки кога ќе се објават на оваа страница.
        </p>
        <br />
        <h1>Контактирајте нè </h1>
        <br />
        <p>
          Доколку имате прашања за оваа Политика за приватност, контактирајте нè
          на е-пошта: prijavi@ssm.org.mk
        </p>
        <p>
          Апликацијата ССМ - Работнички права е поддржана од Меѓународната
          организација на трудот и Европската Унија, преку проектот Зајакнување
          на социјалниот дијалог.
        </p>
        <br />
      </div>
    </>
  );
};
export default PrivacyPolicy;
