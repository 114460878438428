import _remove from 'lodash/remove';
import * as EventActions from '../actions/event';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  eventList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  eventDetails: {
    isFetching: false,
    isFetched: false,
    eventData: [],
  },
};

const setEventListFetching = fetching => state => ({
  ...state,
  eventList: {
    ...state.eventList,
    isFetching: fetching,
  },
});

const setEventList = (state, { payload: { listData, total } }) => ({
  ...state,
  eventList: {
    ...state.eventList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setEventFetching = fetching => state => ({
  ...state,
  eventDetails: {
    ...state.eventDetails,
    isFetching: fetching,
  },
});

const setEvent = (state, { payload: { eventData } }) => ({
  ...state,
  eventDetails: {
    ...state.eventDetails,
    eventData,
    isFetching: false,
    isFetched: true,
  },
});

const setEventDeleted = (state, { payload: { eventId } }) => {
  const newListData = state.eventList.listData;
  _remove(newListData, { id: eventId });

  const newState = {
    ...state,
    eventList: {
      ...state.eventList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [EventActions.LIST_EVENT_REQUEST]: setEventListFetching(true),
  [EventActions.LIST_EVENT_SUCCESS]: setEventList,
  [EventActions.LIST_EVENT_FAILURE]: setEventListFetching(false),
  [EventActions.DELETE_EVENT_SUCCESS]: setEventDeleted,
  [EventActions.FETCH_EVENT_REQUEST]: setEventFetching(true),
  [EventActions.FETCH_EVENT_SUCCESS]: setEvent,
  [EventActions.FETCH_EVENT_FAILURE]: setEventFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


