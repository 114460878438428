import getAxiosInstance from "../config/http";

export const CREATE_POST_REQUEST = "POST@CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "POST@CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "POST@CREATE_POST_FAILURE";

export const createPost = (file, postData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_POST_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(postData));
    const { data } = await http.post("/news-items", formData);
    dispatch({
      type: CREATE_POST_SUCCESS,
      payload: {
        postData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_POST_FAILURE,
    });
    throw e;
  }
};

export const LIST_POST_REQUEST = "POST@LIST_POST_REQUEST";
export const LIST_POST_SUCCESS = "POST@LIST_POST_SUCCESS";
export const LIST_POST_FAILURE = "POST@LIST_POST_FAILURE";

export const listPosts = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_POST_REQUEST,
  });
  try {
    const postData = await http.get("/news-items", {
      params
    });
    dispatch({
      type: LIST_POST_SUCCESS,
      payload: {
        listData: postData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_POST_FAILURE,
    });
  }
};

export const FETCH_POST_REQUEST = "POST@FETCH_POST_REQUEST";
export const FETCH_POST_SUCCESS = "POST@FETCH_POST_SUCCESS";
export const FETCH_POST_FAILURE = "POST@FETCH_POST_FAILURE";

export const fetchPost = (postId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_POST_REQUEST,
  });
  try {
    const postData = await http.get(`/news-items/${postId}`);
    dispatch({
      type: FETCH_POST_SUCCESS,
      payload: {
        postData: postData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_POST_FAILURE,
    });
  }
};

export const UPDATE_POST_REQUEST = "POST@UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "POST@UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "POST@UPDATE_POST_FAILURE";

export const updatePost = (id, file, postData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_POST_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(postData));
    const { data } = await http.put(`/news-items/${id}`, formData, postData);
    dispatch({
      type: UPDATE_POST_SUCCESS,
      payload: {
        postData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: UPDATE_POST_FAILURE,
    });
  }
};

export const DELETE_POST_REQUEST = "POST@DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "POST@DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "POST@DELETE_POST_FAILURE";

export const deletePost = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_POST_REQUEST,
  });
  try {
    const data = await http.delete(`/news-items/${id}`);
    dispatch({
      type: DELETE_POST_SUCCESS,
      payload: {
        postId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_POST_FAILURE,
    });
  }
};
