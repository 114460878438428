import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import avatar from "../../img/nophoto.jpg";

const CommentList = ({ comments, deleteComment, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="inline-block min-w-full shadow rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Реден бр.
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Коментар
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Линк
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Документ
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Објавено на
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                >
                  Акција
                </th>
              </tr>
            </thead>
            <tbody>
              {comments.map((item, idx) => {
                return (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {idx + 1}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.content}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.link}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm overflow-ellipsis">
                      <p className="text-gray-900 whitespace-no-wrap overflow-ellipsis w-28 overflow-hidden">
                        {item.files.map((file) => {
                          return file.mime.startsWith("image/") ? (
                            <a
                              href={
                                item.files.length === 0
                                  ? "#"
                                  : `${process.env.REACT_APP_API_URL}${file.url}`
                              }
                            >
                              <img
                                className="w-20 h-20"
                                src={
                                  item.files.length === 0
                                    ? avatar
                                    : `${process.env.REACT_APP_API_URL}${file.url}`
                                }
                              />
                            </a>
                          ) : (
                            <a
                              className="text-blue"
                              href={`${process.env.REACT_APP_API_URL}${file.url}`}
                            >{`${file.name}`}</a>
                          );
                        })}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {moment(item.updated_at).format("DD.MM.YYYY h:mm a")}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        className="text-red hover:text-red"
                        onClick={() => deleteComment(item.id)}
                      >
                        Избриши
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CommentList;
